import React, { createContext } from 'react'

const defaultState = {
  open: false,
  toggleMenu: () => {},
}

const SlideMenuContext = createContext(defaultState)

class SlideMenuProvider extends React.Component {
  constructor() {
    super()

    this.state = {
      open: false,
      openIndex: null,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.setOpenIndex = this.setOpenIndex.bind(this)
  }

  setOpenIndex(i) {
    this.setState({ openIndex: i })
  }

  toggleMenu() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { children } = this.props
    const { open, openIndex } = this.state

    return (
      <SlideMenuContext.Provider
        value={{
          open,
          openIndex,
          toggleMenu: this.toggleMenu,
          setOpenIndex: this.setOpenIndex,
        }}
      >
        {children}
      </SlideMenuContext.Provider>
    )
  }
}

export default SlideMenuContext
export { SlideMenuProvider }
