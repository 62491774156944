import React, { useEffect } from 'react'
import { Location } from '@reach/router'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QuoteFormProvider } from './src/context/QuoteFormContext'
import { SlideMenuProvider } from './src/context/SlideMenuContext'
import './src/css/theme-helpers.css'
import './src/css/index.css'

const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY

const addQueryParamIfNotExisting = queryParam => {
  const locationQuery = new URLSearchParams(window.location.search)
  if (!locationQuery.get(queryParam.key))
    locationQuery.set(queryParam.key, queryParam.value)
  return `${window.location.pathname}?${locationQuery.toString()}`
}

const addGCLIDToLocationIfFound = () => {
  const gclid = sessionStorage.getItem('gclid')

  if (gclid && !window.location.search.includes('gclid')) {
    window.history.replaceState(
      {},
      '',
      addQueryParamIfNotExisting({ key: 'gclid', value: gclid })
    )
  }
}

const GCLIDProvider = ({ location, children }) => {
  // Note: we are relying on browser session storage to determine length of session for analytics
  useEffect(() => {
    const gclid = new URLSearchParams(window.location.search).get('gclid')
    if (gclid) sessionStorage.setItem('gclid', gclid)
  }, [])

  useEffect(() => {
    addGCLIDToLocationIfFound()
  }, [location])

  return children
}

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      scriptProps={{
        async: true,
      }}
      reCaptchaKey={recaptchaSiteKey}
    >
      <Location>
        {location => (
          <GCLIDProvider location={location}>
            <QuoteFormProvider>
              <SlideMenuProvider>{element}</SlideMenuProvider>
            </QuoteFormProvider>
          </GCLIDProvider>
        )}
      </Location>
    </GoogleReCaptchaProvider>
  )
}
